class GtmService {
    constructor(products = [], product = {}, position = 1, streamData = {}) {
        this.product = product
        this.products = products
        this.position = position
        this.streamData = streamData
    }

    getAttributes(item) {
        if (item.variants && item.variants.length) {
            return item.variants[0].attributes
        }

        if (item.attributes) {
            return item.attributes
        }

        return item.variant.attributes
    }

    getSum() {
        return this.streamData.sum / 100
    }

    getTaxed() {
        return this.streamData.taxed && this.streamData.taxed.taxPortions && this.streamData.taxed.taxPortions.length && this.streamData.taxed.taxPortions[0].amount / 100
    }

    getOrderId() {
        return this.streamData.orderId
    }

    getActiveColor(item) {
        return item.colors.filter((color) => color.isActive)
    }

    getPrice(item) {
        if (item?.price) {
            return item.price / 100
        }

        const priceVariants = item.variants || item.price_variants
        const priceList = new Set(priceVariants.map((variant) => variant.discountedPrice || variant.price))
        return Math.min(...priceList) / 100
    }

    getBrand(item) {
        return this.getAttributes(item).att_prod_brand
    }

    getColor(item) {
        return this.getAttributes(item).att_prod_color_name_mf
    }

    getName(item) {
        return this.getAttributes(item).att_prod_product_name || item.name
    }

    getCategory(item) {
        return this.getAttributes(item).att_internal_category_online_shop
    }

    getVariant(item) {
        if (item.variants && item.variants.length > 0) {
            return item.variants[0]
        }

        return item.variant
    }

    getProductId(item) {
        return item?.sku || this.getVariant(item)?.sku
    }

    createProductListGTM(list, isQuantity, userToken) {
        const productListGTM = []
        let productGTM = {}
        this.products.forEach((item, index) => {
            productGTM = {
                name: this.getName(item),
                id: this.getProductId(item),
                price: this.getPrice(item),
                brand: this.getBrand(item),
                variant: this.getColor(item),
                category: this.getCategory(item),
                position: index + 1,
            }
            if (isQuantity) {
                productGTM.quantity = item.count || 1
            }
            if (list) {
                productGTM.list = list
            }
            if (userToken) {
                productGTM.user_token = userToken
            }
            productListGTM.push(productGTM)
        })
        return productListGTM
    }

    createProductGTM(nameCategory) {
        const productGTM = {
            name: this.getName(this.product),
            id: this.getProductId(this.product),
            price: this.getPrice(this.product),
            brand: this.getBrand(this.product),
            variant: this.getColor(this.product),
            category: this.getCategory(this.product),
            position: this.position,
        }
        if (nameCategory) {
            productGTM.list = nameCategory
        }
        return productGTM
    }

    createProductWithOutPositionGTM() {
        const productGTM = this.createProductGTM()
        delete productGTM.position
        return productGTM
    }

    createProductListWithOutPositionGTM(isQuantity) {
        const productListGTM = this.createProductListGTM(null, isQuantity)
        productListGTM.forEach((product) => {
            delete product.position
        })
        return productListGTM
    }

    createProductWithQuantityGTM(addProps) {
        let productGTM = this.createProductWithOutPositionGTM()
        productGTM = {
            ...productGTM,
            quantity: 1,
            ...addProps,
        }
        return productGTM
    }

    createPurchaseActionFieldGTM() {
        return {
            id: this.getOrderId(),
            revenue: this.getSum(),
            tax: this.getTaxed(),
        }
    }
}

export default GtmService
